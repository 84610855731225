import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";

import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import ProductOne from "@/components/product-one";


import { graphql } from "gatsby"


const CleaningTools = (props) => {
    let data = props.data.wpgraphql;

    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://topaper.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://topaper.gr${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <MenuContextProvider>
            <Layout serving={data.categories} metaData={constructMetaData(data.productCategory, "/ergaleia-katharismou/")} >
                <HeaderOne serving={data.categories} />
                <PageHeader title={data.productCategory.name} name={data.productCategory.name} />
                <ProductOne data={data.productCategory} noCat={true} />
                <Footer />
            </Layout>
        </MenuContextProvider>
    );
};

export default CleaningTools;


export const pageQuery = graphql`query GET_CLEANING_TOOLS {
    wpgraphql {
        productCategory(id: "dGVybTozMDY="){
            seo {
                canonical
                title
                metaDesc
                opengraphDescription
                opengraphTitle
                opengraphImage {
                    sourceUrl
                }
            }
            slug
            name
            products(first:200, where: {orderby: {field: DATE , order: ASC }}){
                edges{
                    node{
                        title
                        slug
                        featuredImage{
                            node{
                                sourceUrl
                                altText
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        categories (first:500) {
            edges {
              node {
                name
                description
                slug
                categoryPageH1{
                  categoryImage{
                    altText
                    sourceUrl
                    imageFile {
                        childImageSharp {
                            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
                        }
                    }
                  }
                }
                children {
                  edges {
                    node {
                      name
                      posts {
                        edges {
                          node {
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        }
    }
}
`